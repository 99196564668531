<template>
  <div class="home">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="mb-3 p-3 bg-white rounded shadow-sm">
          <h6 class="border-bottom border-gray pb-2 mb-0">Class Feed</h6>
          <div class="media text-muted pt-3 border-bottom border-gray">
            <b-img class="card_img" src="https://dummyimage.com/40x40/ef/0" />
            <p class="media-body pb-3 mb-0 small lh-125 ml-3">
              <strong class="d-block text-gray-dark">@username</strong>
              Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
            </p>
          </div>
          <div class="media text-muted pt-3 border-bottom border-gray">
            <b-img class="card_img" src="https://dummyimage.com/40x40/ef/0" />
            <p class="media-body pb-3 mb-0 small lh-125 ml-3">
              <strong class="d-block text-gray-dark">@username</strong>
              Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
            </p>
          </div>
          <small class="d-block text-right mt-3">
            <a href="#">All updates</a>
          </small>
        </div>
      </div>

      <div class="col-xs-12 col-md-6">
        <b-card class="shadow-sm">
          <h6>Favorite Books</h6>
          <b-table class="mb-0" thead-class="d-none" show-empty hover selectable :busy="loading" :items="favoriteBooks"
            :fields="fields" :current-page="currentPage" :per-page="perPage" @row-selected="onRowSelected">
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2" />
                <span>Loading...</span>
              </div>
            </template>
            <template v-slot:cell(list)="cell">
              <div class="media p-1">
                <b-img class="card_img" :src="coverImage(cell.item)" :alt="cell.item.title" :title="cell.item.title" />
                <div class="media-body small ml-3 align-self-stretch d-flex flex-column">
                  <strong class="d-block"><b-link :to="{name: 'book-preview', params: {book_id: cell.item.id}}" :title="cell.item.title">{{ cell.item.title }}</b-link></strong>
                  <p class="text-muted mb-0">
                    {{cell.item.subtitle}}
                    <span class="d-block"><AuthorIcon title="Authors" class="mr-1" />{{ cell.item.author.join(', ') }}</span>
                  </p>
                  <div class="flex-grow-1 d-flex align-items-end justify-content-end">
                    <b-spinner small v-if="(deletingItem === cell.item.id)" />
                    <b-button-close title="Delete" @click="RemoveAsFavorite(cell.item.id)" v-else />
                  </div>
                </div>
              </div>
            </template>
          </b-table>
          <hr class="mt-0" />
          <div class="row">
            <b-pagination class="my-0" size="sm" v-model="currentPage" :per-page="perPage"
              :total-rows="favoriteBooks.length" align="right" v-show="(favoriteBooks.length > perPage)" />
            <button class="btn btn-link btn-sm text-muted ml-auto" @click="RemoveAllAsFavorite" v-if="(favoriteBooks.length > 1)">
              <small><DeleteIcon />Delete All</small>
            </button>
          </div>
        </b-card>
      </div>

    </div>
  </div>
</template>

<script>
import AuthorIcon from 'mdi-vue/AccountMultipleOutline';
import DeleteIcon from 'mdi-vue/TrashCanOutline';
import { mapState } from 'vuex';

export default {
  name: 'home',
  components: { AuthorIcon, DeleteIcon },
  data() {
    return {
      loading: false,
      deletingItem: '',
      currentPage: 1,
      items: [],
      perPage: 5,
      fields: [{ key: 'list', tdClass: 'p-0' }],
    };
  },
  computed: {
    ...mapState('book', ['favoriteBooks']),
  },
  methods: {
    coverImage(book) {
      return (book.coverimage && book.coverimage.length) ? book.coverimage[0] : '';
    },
    getFavoriteBooks() {
      this.loading = true;
      this.$store.dispatch('book/getFavoriteBooks').catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
    RemoveAsFavorite(bookId) {
      this.deletingItem = bookId;
      this.$store.dispatch('book/removeAsFavorite', [bookId]).catch(() => {}).finally(() => {
        this.deletingItem = '';
      });
    },
    RemoveAllAsFavorite() {
      this.$bvModal.msgBoxConfirm('All books will be deleted from your favorite list. Are you sure?', {
        buttonSize: 'sm', centered: true, okVariant: 'danger', okTitle: 'Yes', cancelTitle: 'No',
      })
        .then((reply) => {
          if (reply === true) {
            this.loading = true;
            this.$store.dispatch('book/removeAllFavorites').catch(() => {}).finally(() => {
              this.loading = false;
            });
          }
        })
        .catch(() => { /* ignore */ });
    },
    onRowSelected(item) {
      if (item.length > 0) this.$router.push({ name: 'book-preview', params: { book_id: item[0].id } });
    },
  },

  created() {
    if (!this.favoriteBooks || (this.favoriteBooks.length === 0)) this.getFavoriteBooks();
  },
};
</script>

<style lang="stylus">

.home
  .card_img
    width 3rem

</style>
